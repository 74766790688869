import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "home",
    },
    component: Home,
  },

  // -----------------小程序-------------------
  {
    path: "/userAgreement",
    name: "userAgreement",
    component: () => import("@/views/miniPrograms/userAgreement.vue"),
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: () => import("@/views/miniPrograms/privacyPolicy.vue"),
  },

  //------------------- PC------------------------

  {
    path: "/platformServices",
    name: "platformServices",
    component: () => import("@/views/pcBackend/platformServices.vue"),
  },

  {
    path: "/platformPrivacy",
    name: "platformPrivacy",
    component: () => import("@/views/pcBackend/platformPrivacy.vue"),
  },

  {
    path: "/protectionRules",
    name: "protectionRules",
    component: () => import("@/views/pcBackend/protectionRules.vue"),
  },

  {
    path: "/platformUsers",
    name: "platformUsers",
    component: () => import("@/views/pcBackend/platformUsers.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
