<template>
  <div>
    <div v-if="false">
      <div style="text-align: center; font-size: 20px">首页</div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="PC后台协议" name="first">
        <div class="home">
          <div
            @click="clickPath(item)"
            v-for="(item, index) in pcBackends"
            :key="index"
            class="title"
          >
            <el-link type="success"> {{ item.name }}</el-link>
          </div>
        </div>
      </el-tab-pane>

      <!-- ------------------------------------- -->
      <el-tab-pane label="小程序协议" name="second">
        <div class="home">
          <div
            @click="clickPath(item)"
            v-for="(item, index) in miniPrograms"
            :key="index"
            class="title"
          >
            <el-link type="danger"> {{ item.name }}</el-link>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },

  data() {
    return {
      activeName: "first",
      // 小程序
      miniPrograms: [
        {
          name: "用户协议",
          path: "/userAgreement",
        },
        {
          name: "隐私协议",
          path: "/privacyPolicy",
        },
      ],

      // PC
      pcBackends: [
        {
          name: "平台服务条款",
          path: "/platformServices",
        },
        {
          name: "看看云医平台隐私政策",
          path: "/platformPrivacy",
        },
        {
          name: "看看云医未成年人保护规则",
          path: "/protectionRules",
        },
        {
          name: "看看云医用户协议",
          path: "/platformUsers",
        },
      ],
    };
  },

  methods: {
    clickPath(item) {
      console.log(item);

      this.$router.push({
        path: item.path,
        // query: {
        //   //query是个配置项
        //   age: 20,
        // },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.title {
  padding: 3px 0;
}
</style>
